<template>
	<el-drawer :wrapperClosable="false" :title="$t('i18nn_ea519c59cd7bec7d')" append-to-body :visible.sync="dialogShow"
		:direction="'rtl'" size="500px">
		<div>
			<div class="filterCon" style="">
				<ul class="filterConList" style="text-align: left;">
					<li>
						<span style="display:inline-block;width: 50px;">{{$t('i18nn_54672b9140a3afe0')}}</span>
						<el-select v-model="printerVal" :placeholder="$t('hytxs0000100')" filterable clearable
							style="margin-left: 10px;" @change="changePrinter">
							<el-option v-for="(item, index) in printerList" :key="index" :label="item.name"
								:value="item.value"></el-option>
						</el-select>
					</li>
					<li>
						<span style="display:inline-block;width: 50px;">{{$t('i18nn_a85af4ba66084d28')}}</span>
						<el-select v-model="form.size" :placeholder="$t('hytxs0000100')" filterable clearable
							style="margin-left: 10px;">
							<el-option v-for="(item, index) in selectOption.sizeList" :key="index" :label="item.label"
								:value="item.value"></el-option>
						</el-select>
						
						<!-- <el-radio-group v-model="form.size">
							<el-radio v-for="(item,index) in selectOption.sizeList" :key="index" :label="item.value">
								{{item.label}}
							</el-radio>
						</el-radio-group> -->
					</li>
					
				</ul>
			</div>
			<div class="palletMarkList" id="palletMarkList" v-loading="loading">
				<div class="palletMarkCon" v-for="(item,index) in list" :key="index">
					<!-- <div class="palletMarkLogo">
						<img src="../../../assets/logo_en.png" width="80" height="auto" />
					</div> -->
					<div class="palletMarkTitle">
						<img :src="$store.state.sysShow.logoInfo.logo" width="80" height="auto" />
					</div>
					<ul>
						<li>
							<span class="mark-name">Customer #</span>
							<span v-show="!item.CusIsCn">
								{{item.customer}}
							</span>
							<span v-show="item.CusIsCn" class="mark-customer">
								<imgFont :text="item.customer" :options="{
									width: 300,
									height: 22,
									fontSize: 18,
								}"></imgFont>

							</span>
						</li>
						<li>
							<span class="mark-name">Container #</span>
							<span v-show="!item.ConIsCn">
								{{item.container}}
							</span>
							<span v-show="item.ConIsCn" class="mark-container">
								<imgFont :text="item.container" :options="{
									width: 300,
									height: 22,
									fontSize: 18,
								}"></imgFont>
							</span>
						</li>
						<li>
							<span class="mark-name">Pallet #</span>
							<span class="mark-pallet">
								{{item.pallet}}
							</span>
						</li>
						<li>
							<span class="mark-name">Date #</span>
							<span class="mark-date">
								{{item.date}}
							</span>
						</li>
					</ul>
				</div>

			</div>
		</div>
		<div class="drawer-footer">

			<el-button type="primary" icon="el-icon-printer" @click="previewAction()">{{$t('i18nn_17a941b7c4c108fe')}}</el-button>
			<!-- <el-button type="primary" icon="el-icon-printer" @click="printerAction()">{{$t('i18nn_886072e393710563')}}</el-button> -->
			<el-button type="warning" icon="el-icon-download" @click="downloadAction()">{{$t('i18nn_245883c80f181c4a')}}</el-button>

			<!-- <el-button type="primary" style="width: 220px;" @click="submitForm('form', '3')" v-if="!!form.id">{{ $t('i18nn_d552aa9f1c14363f') }}</el-button> -->
			<!-- <el-button type="primary" icon="el-icon-view" @click="submitForm('form', '2')">{{$t('i18nn_17a941b7c4c108fe')}}</el-button> -->
			<!-- <el-button type="warning" icon="el-icon-download"  style="width: 220px;" @click="submitForm('form', '1')">{{$t('i18nn_245883c80f181c4a')}}</el-button> -->
			<!-- </div> -->
			<!-- <div style="margin: 10px 0; text-align: center;"> -->
			<!-- <el-button type="primary" style="width: 220px;" @click="submitForm('form', '3')" v-if="!!form.id">{{ $t('i18nn_d552aa9f1c14363f') }}</el-button> -->
			<!-- <el-button type="primary" icon="el-icon-view"  style="width: 220px;" @click="submitForm('form', '2')">{{$t('i18nn_17a941b7c4c108fe')}}</el-button> -->
			<!-- <el-button type="warning" icon="el-icon-download"  @click="submitForm('form', '1')">{{$t('i18nn_245883c80f181c4a')}}</el-button> -->
		</div>
	</el-drawer>
</template>
<script>
	import {
		downloadPdfBase64
	} from '@/utils/pdfUtils.js';
	import {
		priterPdfView,
		GetPrinterList,
	} from '@/utils/LodopPrinter.js';

	import {
		exportPdfByPalletMark
	} from '@/utils/PdfPalletMark.js'

	import imgFont from '@/components/Common/imgFont.vue';

	export default {
		components: {
			imgFont
		},
		props: {
			openTime: {},
			// url: {},
			row: {}
		},
		data() {
			return {
				dialogShow: false,

				printerVal: "",
				printerList: [],
				//表格数据
				//loading,表格数据
				loading: false,
				loading_load: false,
				//详情数据
				loading_det: false,

				list: [],
				
				selectOption: {
					sizeList: [
					{
						label: "101.6*152.4(4*6in)",
						value: "1"
					},{
						label: "210*297(A4)",
						value: "2"
					}],
				},
				
				form: {
					size: '1',
				},

				// formRules: {
				// 	customer: [{
				// 		required: true,
				// 		message: this.$t('5a9aefbc03c778f7'),
				// 		trigger: 'blur'
				// 	}],
				// 	container: [{
				// 		required: true,
				// 		message: this.$t('5a9aefbc03c778f7'),
				// 		trigger: 'blur'
				// 	}],
				// 	pallet: [{
				// 		required: true,
				// 		message: this.$t('5a9aefbc03c778f7'),
				// 		trigger: 'change'
				// 	}],
				// 	date: [{
				// 		required: true,
				// 		message: this.$t('5a9aefbc03c778f7'),
				// 		trigger: 'change'
				// 	}],
				// },
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				this.dialogShow = true;
				this.initData();
			}
		},
		activated() {

		},
		//创建时
		created() {

		},
		//编译挂载前
		mounted() {
			
		},
		methods: {
			initData() {
				this.initPriterList();
				// this.resetForm('form');
				let row = this.row;
				let list = [];
				let pallet = parseInt(row.pallet);
				if(pallet && !isNaN(pallet)){
					for(let i=0,ln=pallet;i<ln;i++){
						let CusIsCn = /[\u4e00-\u9fa5]+/.test(row.customer);
						let ConIsCn = /[\u4e00-\u9fa5]+/.test(row.container);
						list.push({
							customer: row.customer,
							CusIsCn: CusIsCn,
							container: row.container,
							ConIsCn: ConIsCn,
							pallet: (i+1)+"/"+row.pallet,
							date: row.date
						});
					}
				} else {
					let CusIsCn = /[\u4e00-\u9fa5]+/.test(row.customer);
					let ConIsCn = /[\u4e00-\u9fa5]+/.test(row.container);
					list.push({
						customer: row.customer,
						CusIsCn: CusIsCn,
						container: row.container,
						ConIsCn: ConIsCn,
						pallet: row.pallet,
						date: row.date
					});
				}
				// row.forEach((item)=>{
				// 	let CusIsCn = /^[\u4e00-\u9fa5]+$/.test(item.customer);
				// 	let ConIsCn = /^[\u4e00-\u9fa5]+$/.test(item.container);
				// 	list.push({
				// 		customer: item.customer,
				// 		CusIsCn: CusIsCn,
				// 		container: item.container,
				// 		ConIsCn: ConIsCn,
				// 		pallet: item.pallet,
				// 		date: item.date
				// 	});
				// });
				this.list = list;
			},
			//初始化打印机列表
			initPriterList() {
				let printerList = GetPrinterList();
				console.log('printerList', printerList);
				this.printerList = printerList;
			},
			//保存选择的打印机
			changePrinter(v) {
				//保存此次打印机的值
				// savePrtVal(this.printerVal);
				// this.printerVal = v;
				// this.initPagesizesList();
			},
			//预览
			previewAction() {
				this.$nextTick(()=>{
					let listData = this.list;
					let data = exportPdfByPalletMark("#palletMarkList",listData, "PalletMark", {
						size: this.form.size
					},'2');
					// console.log(data);
					// priterPdfView();
					priterPdfView("palletMark", '',data, this.printerVal);
				});
				
			},
			//打印
			// printerAction() {

			// },
			//下载
			downloadAction() {
				this.$nextTick(()=>{
					let listData = this.list;
					exportPdfByPalletMark("#palletMarkList",listData, "PalletMark", {
						size: this.form.size
					},'1');
				});
			},
			// resetForm(formName) {
			// 	console.log(formName);
			// 	this.form = {
			// 		customer: null,
			// 		container: null,
			// 		pallet: null,
			// 		date: null,
			// 	}
			// 	// if (this.$refs[formName]) {
			// 	// 	this.$refs[formName].resetFields();
			// 	// 	// this.form.remark = ""; //  备注//非必填
			// 	// } else {
			// 	// 	console.log('this.$refs[formName]', this.$refs[formName]);
			// 	// }
			// },

			//提交信息
			// submitForm(formName, type) {
			// 	// this.$refs[formName].validate(valid => {
			// 		// if (true) {
			// 		// if (valid) {
			// 			//新增
			// 			// let formData = Object.assign({}, this.form);
			// 			// formData.bizType = '10';
			// 			// downloadPdfBase64(this.$urlConfig.HyPallteLableDownload,formData,'palletMark'+'_'+formData.container);

			// 			this.postData(this.url+"/"+this.row.id, {}, data => {
			// 				console.log('postData', data);
			// 				// window.location.href=data;
			// 				if(1==type){//下载
			// 					downloadPdfBase64(data, 'palletMark' + '_' + this.row.id);
			// 					this.$message.success(this.$t('i18nn_6fb720f73efb6f5f'));
			// 				} else {
			// 					// priterPdfView();
			// 					priterPdfView("palletMark", '',data, this.printerVal);
			// 				}

			// 			});
			// 	// 	} else {
			// 	// 		console.log('error submit!!');
			// 	// 		this.$alert(this.$t('hytxs0000031'), this.$t('cc62f4bf31d661e3'), {
			// 	// 			type: 'warning'
			// 	// 		});
			// 	// 		return false;
			// 	// 	}
			// 	// });
			// },

			//提交数据
			// postData(url, formData, callback) {
			// 	// let _this = this;
			// 	this.loading = true;

			// 	this.$http
			// 		.get(url, formData)
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log(this.$t('i18nn_bc868e024b80d2e3'));
			// 			console.log(data);

			// 			this.loading = false;
			// 			// callback(data.data);
			// 			if (200 == data.code) {
			// 				callback(data.data);
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('dbe331ab679cd67f');
			// 				}
			// 				this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
			// 					type: 'warning'
			// 				});
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log(this.$t('i18nn_a7d2e953195a5588'));
			// 			this.loading = false;
			// 			this.$alert(this.$t('33bae59bb784a109'), this.$t('cc62f4bf31d661e3'), {
			// 				type: 'warning'
			// 			});
			// 		});
			// },


		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.palletMarkList {
		.palletMarkCon {
			margin-bottom: 20px;
			padding: 20px;
			border-bottom: 1px solid #eee;
			.palletMarkLogo {
				margin-left: 200px;
			}

			// font-weight: bold;
			.palletMarkTitle {
				font-size: 22px;
				font-weight: bold;
			}

			// table {
			// 	tr {
			// 		td {
			// 			font-size: 18px;
			// 		}
			// 	}
			// }
			ul {
				font-size: 18px;
				li {
					margin: 10px 0;
					span {
						display: inline-block;
						vertical-align: middle;
					}
					.mark-name {
						font-size: 20px;
						width: 120px;
					}
				}
			}
		}
	}
</style>