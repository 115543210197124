<template>
	<div class="">
		<div class="tableConTop">
			<el-row>
				<el-col :span="6" class="tableConTopLeft">
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item>
							<span class="breadcrumbLink" @click="backPageAction()">
								<i class="el-icon-back iconBack"></i>{{$t('ca5a01a5adf20fe7')}}</span>
						</el-breadcrumb-item>
						<el-breadcrumb-item>{{ pageTitle }}<span>(常规)</span></el-breadcrumb-item>
					</el-breadcrumb>

				</el-col>
				<el-col :span="18" class="tableConTopRig">

				</el-col>
			</el-row>
		</div>
		<!-- <div class="filterCon" style=""> -->
			<!-- <ul class="filterConList" style="text-align: left;"> -->
				<!-- <li>
					<span>{{$t('i18nn_54672b9140a3afe0')}}</span>
					<el-select v-model="printerVal" :placeholder="$t('hytxs0000100')" filterable
						clearable style="margin-left: 10px;"
						@change="changePrinter">
						<el-option v-for="(item, index) in printerList" :key="index"
							:label="item.name" :value="item.value"></el-option>
					</el-select>
				</li> -->
				<!-- <li>
					<span>{{$t('i18nn_a85af4ba66084d28')}}</span>
					<el-select filterable v-model="printerSize"
						:placeholder="$t('2ad108ab2c560530')" style="width: 150px;">
						<el-option v-for="(item, index) in sizeList" :key="index"
							:label="item.label" :value="item.value"></el-option>
					</el-select>
				</li> -->
			<!-- </ul> -->
		<!-- </div> -->
		<div class="">
			<el-form ref="form" :size="''" :rules="formRules" :model="form" label-width="120px" style="width: 100%"
				v-loading="loading" :element-loading-text="$t('44bd089554515c4d')">

				<el-card>
					<div>
						<el-form-item :label="'Customer #'" prop="customer">
							<el-input type="text" clearable v-model="form.customer" style="width:300px;"></el-input>
						</el-form-item>

						<el-form-item :label="'Container #'" prop="container">
							<el-input type="text" clearable v-model="form.container" style="width:300px;"></el-input>
						</el-form-item>
						<el-form-item :label="'Pallet #'" prop="pallet">
							<el-input-number v-model="form.pallet" controls-position="right"></el-input-number>
						</el-form-item>

						<el-form-item :label="'Date #'" prop="date">
							<!-- <el-input type="text" v-model="form.date"></el-input> -->

							<el-date-picker v-model="form.date" type="date" :placeholder="$t('68efabd029c860a9')" format="MM/dd/yyyy"
								value-format="MM/dd/yyyy">
							</el-date-picker>
						</el-form-item>


					</div>
				</el-card>

			</el-form>
			<div style="margin: 10px 0;">
				<!-- <el-button type="primary" style="width: 220px;" @click="submitForm('form', '3')" v-if="!!form.id">{{ $t('i18nn_d552aa9f1c14363f') }}</el-button> -->
				<!-- <el-button type="primary" icon="el-icon-view"  style="width: 220px;" @click="submitForm('form', '2')">{{$t('i18nn_17a941b7c4c108fe')}}</el-button> -->
				<!-- <el-button type="warning" icon="el-icon-download"  style="width: 220px;" @click="submitForm('form', '1')">{{$t('i18nn_245883c80f181c4a')}}</el-button> -->
				
				<el-button type="primary" icon="el-icon-view"  style="width: 220px;" @click="openPreview()">{{$t('04e722a308964860')}}</el-button>
				
				
				
			
			</div>
		</div>
		<!-- </el-dialog> -->
		<!-- 托盘标打印预览 -->
		<dialogPalletMarkImg :openTime="palletMarkImgOpenTime" :row="palletMarkImgRow"></dialogPalletMarkImg>
	</div>
</template>
<script>
	import dialogPalletMarkImg from '@/components/WarehouseCenter2/Other/dialogPalletMarkImg.vue';
	
	// import {
	// 	downloadPdfBase64
	// } from '@/utils/pdfUtils.js';
	// import {
	// 	priterPdfView,
	// 	GetPrinterList,
	// } from '@/utils/LodopPrinter.js';
	export default {
		components: {
			dialogPalletMarkImg
		},
		data() {
			return {
				pageTitle: this.$t('i18nn_ea519c59cd7bec7d'),
				// printerVal: "",
				// printerList: [],
				//表格数据
				//loading,表格数据
				loading: false,
				loading_load: false,
				//详情数据
				loading_det: false,
				
				//托盘标预览-图片
				palletMarkImgOpenTime:'',
				palletMarkImgRow:{},

				form: {
					customer: null,
					container: null,
					pallet: null,
					date: null,
				},

				formRules: {
					customer: [{
						required: true,
						message: this.$t('5a9aefbc03c778f7'),
						trigger: 'blur'
					}],
					container: [{
						required: true,
						message: this.$t('5a9aefbc03c778f7'),
						trigger: 'blur'
					}],
					pallet: [{
						required: true,
						message: this.$t('5a9aefbc03c778f7'),
						trigger: 'change'
					}],
					date: [{
						required: true,
						message: this.$t('5a9aefbc03c778f7'),
						trigger: 'change'
					}],
				},
			};
		},
		activated() {
			this.initData();
		},
		//创建时
		created() {

		},
		//编译挂载前
		mounted() {
			// this.initPriterList();
		},
		methods: {
			initData() {
				this.resetForm('form');
			},
			backPageAction() {
				this.$router.go(-1);
			},
			//初始化打印机列表
			// initPriterList() {
			// 	let printerList = GetPrinterList();
			// 	console.log('printerList', printerList);
			// 	this.printerList = printerList;
			// },
			//保存选择的打印机
			// changePrinter(v) {
				//保存此次打印机的值
				// savePrtVal(this.printerVal);
				// this.printerVal = v;
				// this.initPagesizesList();
			// },
			
			//打开托盘标预览
			openPreview(){
				this.$refs['form'].validate(valid => {
					if (valid) {
						this.palletMarkImgRow = this.form;
						this.palletMarkImgOpenTime = new Date().getTime();
					} else {
						// console.log('error submit!!');
						this.$alert(this.$t('hytxs0000031'), this.$t('cc62f4bf31d661e3'), {
							type: 'warning'
						});
						return false;
					}
				});
			},
			
			resetForm(formName) {
				console.log(formName);
				this.form = {
					customer: null,
					container: null,
					pallet: null,
					date: null,
				}
				if (this.$refs[formName]) {
					this.$refs[formName].resetFields();
					// this.form.remark = ""; //  备注//非必填
				} else {
					console.log('this.$refs[formName]', this.$refs[formName]);
				}
			},

			//提交信息
			// submitForm(formName, type) {
			// 	this.$refs[formName].validate(valid => {
			// 		// if (true) {
			// 		if (valid) {
			// 			//新增
			// 			let formData = Object.assign({}, this.form);
			// 			// formData.bizType = '10';
			// 			// downloadPdfBase64(this.$urlConfig.HyPallteLableDownload,formData,'palletMark'+'_'+formData.container);

			// 			this.postData(this.$urlConfig.HyPallteLableDownload, formData, data => {
			// 				console.log('postData', data);
			// 				// window.location.href=data;
			// 				if(1==type){//下载
			// 					downloadPdfBase64(data, 'palletMark' + '_' + formData.container);
			// 					this.$message.success(this.$t('i18nn_6fb720f73efb6f5f'));
			// 				} else {
			// 					// priterPdfView();
			// 					priterPdfView("palletMark", '',data, this.printerVal);
			// 				}
							
			// 			});
			// 		} else {
			// 			console.log('error submit!!');
			// 			this.$alert(this.$t('hytxs0000031'), this.$t('cc62f4bf31d661e3'), {
			// 				type: 'warning'
			// 			});
			// 			return false;
			// 		}
			// 	});
			// },
			
			//提交数据
			// postData(url, formData, callback) {
			// 	// let _this = this;
			// 	this.loading = true;

			// 	this.$http
			// 		.put(url, formData)
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log(this.$t('i18nn_bc868e024b80d2e3'));
			// 			console.log(data);
						
			// 			this.loading = false;
			// 			// callback(data.data);
			// 			if (200 == data.code) {
			// 				callback(data.data);
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('dbe331ab679cd67f');
			// 				}
			// 				this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
			// 					type: 'warning'
			// 				});
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log(this.$t('i18nn_a7d2e953195a5588'));
			// 			this.loading = false;
			// 			this.$alert(this.$t('33bae59bb784a109'), this.$t('cc62f4bf31d661e3'), {
			// 				type: 'warning'
			// 			});
			// 		});
			// },

			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_op_status', 'wh_fin_bill_status', 'wh_add_sf_type',
			// 			'wh_add_sf_price_type'
			// 		])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				// this.selectOption.wh_op_is_bill = data.data['wh_op_is_bill'];
			// 				this.selectOption.wh_op_status = data.data['wh_op_status'];
			// 				this.selectOption.wh_fin_bill_status = data.data['wh_fin_bill_status'];
			// 				this.selectOption.wh_add_sf_type = data.data['wh_add_sf_type'];
			// 				this.selectOption.wh_add_sf_price_type = data.data['wh_add_sf_price_type'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('hytxs0000032');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('hytxs0000033'));
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>