import { render, staticRenderFns } from "./PalletMark.vue?vue&type=template&id=bedc18cc&scoped=true&"
import script from "./PalletMark.vue?vue&type=script&lang=js&"
export * from "./PalletMark.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bedc18cc",
  null
  
)

export default component.exports